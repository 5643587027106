
import firebase from "firebase/app";
import MSInfoItem from "@/components/setting/MSInfoItem.vue";
import MSReflectionDisplayModal from "@/components/MSReflectionDisplayModal.vue";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import { awaitStudentLoaded } from "@/store/index";
import { Student } from "@/entities/student";
import { Unit, unitCollectionKey, convertToUnit } from "@/entities/reflection";
import { signOut } from "@/api/auth";

@Options({
  components: {
    MSInfoItem,
    MSReflectionDisplayModal
  }
})
export default class Todos extends Vue {
  student: Student | null = null;
  unit: Unit | null = null;
  units: Unit[] = [];
  loading = false;
  isDisplayModalOpen = false;
  paramUid = "";
  logInState:
    | "authMatched"
    | "authMisMatch"
    | "authNotLoggedIn"
    | "error"
    | "" = "";

  // 開発時はローカルホストを復活させて検証
  safeOrigins = [
    "https://school-ai-system.web.app/",
    "https://school-ai-system-stg.web.app/"
    // "http://localhost"
  ];

  get translatedValue() {
    return store.state.translatedValue;
  }

  openDisplayModal(unitId: string) {
    const targetUnit = this.units.find(_ => _.ref.id === unitId);
    if (!targetUnit) {
      // 通常ありえない
      alert("指定の単元が見つかりません。");
      return;
    }
    this.unit = { ...targetUnit };
    this.isDisplayModalOpen = true;
  }

  closeDisplayModal() {
    this.isDisplayModalOpen = false;
    this.unit = null;
  }

  validateReferer(): boolean {
    const referer = document.referrer;
    if (!referer) return true;
    return this.safeOrigins.some(
      origin => referer === origin || referer.startsWith(origin)
    );
  }

  handleAiReflectionUpdate(result: { unitId: string; content: string }) {
    if (!this.unit) return;
    if (!result.unitId) return;
    if (this.unit.data.uid !== result.unitId) return; //普通あり得ない
    const { unitId, content } = result;
    this.unit.data.aiReflection = content;
    this.units = this.units.map(u =>
      u.data.uid === unitId ? ({ ...this.unit } as Unit) : ({ ...u } as Unit)
    );
  }

  getParamUid(): string {
    const uid = this.$route.params.studentUid;
    return uid ? (uid as string) : "";
  }

  async getAuthUid(): Promise<string> {
    const currentUser = firebase.auth().currentUser;
    if (currentUser) return currentUser.uid;
    return new Promise((resolve, reject) => {
      const unsubscribe = firebase.auth().onAuthStateChanged(
        user => {
          resolve(user ? user.uid : ""); //未ログインの場合は空文字を返す
          unsubscribe();
        },
        () => reject("Error occurred in the process of getting auth")
      );
    });
  }

  async getAuthState(): Promise<
    "authMatched" | "authMisMatch" | "authNotLoggedIn" | "error"
  > {
    const paramUid = this.getParamUid();
    if (!paramUid) return "error";
    this.paramUid = paramUid;

    const authUid = await this.getAuthUid();
    if (!authUid) return "authNotLoggedIn";

    return paramUid === authUid ? "authMatched" : "authMisMatch";
  }

  async created() {
    this.loading = true;
    this.safeOrigins.push(window.location.href);

    if (!this.validateReferer()) {
      alert("アクセスできません。ログインしてください。");
      await signOut();
      this.$router.replace("/login");
      return;
    }

    try {
      this.logInState = await this.getAuthState();
    } catch (e) {
      console.error(e);
      alert("ユーザー情報の取得に失敗しました。再度ログインしてください。");
      await signOut();
      this.$router.replace("/login");
      return;
    }

    switch (this.logInState) {
      case "authMatched":
        // とくにすることなし
        break;

      case "authMisMatch":
      case "authNotLoggedIn":
        {
          try {
            store.commit("SET_LOADING", true);
            store.commit("SET_LOAD_TEXT", "ユーザー切り替え中...");
            const call = firebase
              .app()
              .functions("asia-northeast1")
              .httpsCallable("generate_student_custom_token");
            const { data } = await call({ uid: this.paramUid });
            const { token } = data;
            await firebase.auth().signInWithCustomToken(token);
            this.$router.go(0);
          } catch (e) {
            console.error(e);
            alert(
              "ユーザーの切り替えに失敗しました。再度ログインしてください。"
            );
            await signOut();
            this.$router.replace("/login");
            return;
          }
        }
        return;

      case "error":
      default:
        alert("ユーザー情報が取得できません。");
        await signOut();
        this.$router.replace("/login");
        return;
    }

    this.student = (await awaitStudentLoaded(store)) as Student;
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.loading = false;
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "情報取得中...");

      const snapshot = await this.student.ref
        .collection(unitCollectionKey)
        .get();

      this.units = !snapshot.empty
        ? snapshot.docs
            .filter(doc => doc.exists && doc.data())
            .map(doc => convertToUnit(doc.data(), doc.ref))
        : [];
    } catch (e) {
      alert("情報の取得に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.loading = false;
    }
  }
}
