<template>
  <MBaseModal
    body-background-color="bg-primary-100"
    :height="status === 'display' ? 'tall' : 'normal'"
    @close="close"
  >
    <template #title>{{
      translatedValue(generateObjectPath("title"))
    }}</template>
    <template #body>
      <main
        class="w-full h-full relative pt-20 sm:pt-0"
        :class="{ 'pt-none': status !== 'display' }"
      >
        <div v-if="status === 'display'" class="reflection-wrapper">
          <section class="mb-8">
            <h1 class="text-2xl font-bold mb-2">
              {{
                translatedValue(generateObjectPath("contentsOf"), { unitName })
              }}
            </h1>
            <div class="mb-4">
              <h2 class="text-xl mb-1">{{ graphTitle }}</h2>
              <MSLineChart
                v-if="reflections.length > 0"
                class="line-chart-canvas"
                :labels="labels"
                :datasets="datasets"
                :min="min"
                :max="max"
              />
              <div
                v-else
                class="flex items-center justify-center w-4/5 h-24 mx-auto bg-white rounded-lg text-base text-center text-gray-500"
              >
                {{ translatedValue(generateObjectPath("noDataMessage")) }}
              </div>
            </div>
            <div class="mb-4">
              <h2 class="text-xl mb-1">{{ secondRowTitle }}</h2>
              <div class="flex flex-col w-4/5 mx-auto">
                <table class="border-collapse w-full">
                  <tr class="bg-gray-300">
                    <th class="w-1/5 border font-medium border-gray-400 p-1">
                      {{ translatedValue(generateObjectPath("dateHeading")) }}
                    </th>
                    <th class="w-4/5 border font-medium border-gray-400 p-1">
                      {{
                        translatedValue(generateObjectPath("contentHeading"))
                      }}
                    </th>
                  </tr>
                  <tr v-for="(r, i) in reflections" :key="i" class="bg-white">
                    <td class="w-1/5 border border-gray-400 p-1">
                      {{ formatDate(r.data.uid) }}
                    </td>
                    <td class="w-4/5 border border-gray-400 p-1 ">
                      <div class="w-full flex flex-row gap-1 items-center">
                        <span
                          class="block flex-1 break-all whitespace-normal"
                          >{{ r.data.reflection }}</span
                        >
                        <MIcon
                          type="pencil"
                          class="text-gray-500 mr-1 cursor-pointer"
                          @click="openRecordEditInput(r.data.uid)"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <div>
              <h2 class="text-xl mb-1">{{ thirdRowTitle }}</h2>
              <div class="flex flex-col w-4/5 mx-auto">
                <table class="border-collapse w-full">
                  <tr class="bg-gray-300">
                    <th class="w-1/5 border font-medium border-gray-400 p-1">
                      {{ translatedValue(generateObjectPath("dateHeading")) }}
                    </th>
                    <th class="w-4/5 border font-medium border-gray-400 p-1">
                      {{
                        translatedValue(generateObjectPath("contentHeading"))
                      }}
                    </th>
                  </tr>
                  <tr v-for="(r, i) in reflections" :key="i" class="bg-white">
                    <td class="w-1/5 border border-gray-400 p-1">
                      {{ formatDate(r.data.uid) }}
                    </td>
                    <td class="w-4/5 border border-gray-400 p-1 ">
                      <div class="w-full flex flex-row gap-1 items-center">
                        <span
                          class="block flex-1 break-all whitespace-normal"
                          >{{ r.data.description }}</span
                        >
                        <MIcon
                          type="pencil"
                          class="text-gray-500 mr-1 cursor-pointer"
                          @click="openRecordEditInput(r.data.uid)"
                        />
                      </div>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </section>
          <section>
            <h1 class="text-2xl font-bold mb-2">
              {{ translatedValue(generateObjectPath("unitReflectionHeading")) }}
            </h1>
            <div class="mb-4">
              <h2 class="text-xl mb-1">
                {{ translatedValue(generateObjectPath("selfReflection")) }}
              </h2>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{ 'text-gray-500': !unit || !unit.data.selfReflection }"
              >
                {{
                  unit?.data.selfReflection ||
                    translatedValue(generateObjectPath("noCommentMessage"))
                }}
              </div>
            </div>
            <div class="mb-4">
              <h2 class="text-xl mb-1">
                {{ translatedValue(generateObjectPath("teacherReflection")) }}
              </h2>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{
                  'text-gray-500': !unit || !unit.data.teacherReflection
                }"
              >
                {{
                  unit?.data.teacherReflection ||
                    translatedValue(generateObjectPath("noCommentMessage"))
                }}
              </div>
            </div>
            <div class="pb-4">
              <div class="flex flex-row items-center gap-4 mb-1">
                <h2 class="text-xl">
                  {{ translatedValue(generateObjectPath("aiReflection")) }}
                </h2>
                <div class="flex flex-row items-center gap-2">
                  <button
                    v-if="reflections.length > 0"
                    class="w-20 bg-white rounded-md py-1 border text-sm flex items-center justify-center "
                    :class="[
                      unit && unit.data.selfReflection
                        ? 'border-secondary-500 text-secondary-500 hover:bg-gray-100 focus:outline-none transition-all duration-300'
                        : 'border-gray-500 text-gray-500 cursor-default'
                    ]"
                    :disabled="!(unit && unit.data.selfReflection)"
                    @click.prevent="generateAiReflection"
                  >
                    {{ translatedValue(generateObjectPath("generate")) }}
                  </button>
                  <button
                    v-if="unit && unit.data.aiReflection"
                    class="w-20 bg-white rounded-md py-1 border border-red-500 text-red-500 text-sm flex items-center justify-center hover:bg-gray-100 focus:outline-none transition-all duration-300"
                    @click.prevent="deleteAiReflection"
                  >
                    {{ translatedValue(generateObjectPath("delete")) }}
                  </button>
                </div>
              </div>
              <p class="text-sm mb-1">
                {{ translatedValue(generateObjectPath("cautionForAiContent")) }}
              </p>
              <p
                v-if="
                  reflections.length > 0 && unit && !unit.data.selfReflection
                "
                class="text-xs text-gray-600 pb-1"
              >
                {{
                  translatedValue(
                    generateObjectPath("cautionForAiReflectionGeneration")
                  )
                }}
              </p>
              <div
                class="w-4/5 mx-auto bg-white p-2 break-all whitespace-pre-line"
                :class="{
                  'text-gray-500': !unit || !unit.data.aiReflection
                }"
              >
                {{
                  unit?.data.aiReflection ||
                    translatedValue(generateObjectPath("noCommentMessage"))
                }}
              </div>
            </div>
          </section>
        </div>
        <div
          v-else-if="status === 'recordInput'"
          class="reflection-wrapper flex flex-col gap-8"
        >
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">
              {{ translatedValue(generateObjectPath("dateOfClass")) }}
            </h2>
            <MTextField
              type="date"
              :value="recordInput.recordDate"
              @input="recordInput.recordDate = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ graphTitle }}</h2>
            <MTextField
              type="number"
              :value="recordInput.record"
              :entered="recordEntered"
              :invalid="!validRecordInput"
              invalid-message="値が不正です。"
              @input="recordInput.record = $event"
              @enter="
                () => {
                  recordEntered = true;
                  if (recordInput.record !== 0 && !recordInput.record) {
                    validRecordInput = false;
                  } else {
                    validRecordInput = true;
                  }
                }
              "
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ secondRowTitle }}</h2>
            <MTextField
              type="text"
              :value="recordInput.reflection"
              :invalid="!validRecordReflectionInput"
              invalid-message="振り返りを入力してください。"
              :entered="recordReflectionEntered"
              @enter="
                () => {
                  recordReflectionEntered = true;
                  if (recordInput.reflection.length > 0) {
                    validRecordReflectionInput = true;
                  } else {
                    validRecordReflectionInput = false;
                  }
                }
              "
              @input="recordInput.reflection = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ thirdRowTitle }}</h2>
            <MTextField
              type="text"
              :value="recordInput.description"
              :invalid="!validRecordDescriptionInput"
              invalid-message="入力してください。"
              :entered="recordDescriptionEntered"
              @enter="
                () => {
                  recordDescriptionEntered = true;
                  if (recordInput.description.length > 0) {
                    validRecordDescriptionInput = true;
                  } else {
                    validRecordDescriptionInput = false;
                  }
                }
              "
              @input="recordInput.description = $event"
            />
          </div>
        </div>
        <div
          v-else-if="status === 'recordEdit'"
          class="reflection-wrapper flex flex-col gap-8"
        >
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">
              {{ translatedValue(generateObjectPath("dateOfClass")) }}
            </h2>
            <MTextField
              type="date"
              :value="eRecordInput.recordDate"
              @input="eRecordInput.recordDate = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ graphTitle }}</h2>
            <MTextField
              type="number"
              :value="eRecordInput.record"
              :entered="eRecordEntered"
              :invalid="!eValidRecordInput"
              invalid-message="値が不正です。"
              @input="eRecordInput.record = $event"
              @enter="
                () => {
                  eRecordEntered = true;
                  if (eRecordInput.record !== 0 && !eRecordInput.record) {
                    eValidRecordInput = false;
                  } else {
                    eValidRecordInput = true;
                  }
                }
              "
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ secondRowTitle }}</h2>
            <MTextField
              type="text"
              :value="eRecordInput.reflection"
              :invalid="!eValidRecordReflectionInput"
              invalid-message="振り返りを入力してください。"
              :entered="eRecordReflectionEntered"
              @enter="
                () => {
                  eRecordReflectionEntered = true;
                  if (eRecordInput.reflection.length > 0) {
                    eValidRecordReflectionInput = true;
                  } else {
                    eValidRecordReflectionInput = false;
                  }
                }
              "
              @input="eRecordInput.reflection = $event"
            />
          </div>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">{{ thirdRowTitle }}</h2>
            <MTextField
              type="text"
              :value="eRecordInput.description"
              :invalid="!eValidRecordDescriptionInput"
              invalid-message="入力してください。"
              :entered="eRecordDescriptionEntered"
              @enter="
                () => {
                  eRecordDescriptionEntered = true;
                  if (eRecordInput.description.length > 0) {
                    eValidRecordDescriptionInput = true;
                  } else {
                    eValidRecordDescriptionInput = false;
                  }
                }
              "
              @input="eRecordInput.description = $event"
            />
          </div>
        </div>
        <div
          v-else-if="status === 'unitReflectionInput'"
          class="reflection-wrapper"
        >
          <h1 class="font-medium text-2xl text-center mb-8">
            {{
              translatedValue(
                generateObjectPath("registerReflectionForUnitName"),
                {
                  unitName
                }
              )
            }}
          </h1>
          <div class="w-4/5 mx-auto flex flex-row items-center">
            <h2 class="font-normal text-lg w-32">
              {{ translatedValue(generateObjectPath("reflection")) }}
            </h2>
            <MTextArea
              :value="unitReflection"
              height="tall"
              @input="unitReflection = $event"
              @enter="
                () => {
                  if (unit && unit.data.selfReflection) {
                    validUnitReflection = true;
                  } else {
                    if (unitReflection.length > 0) {
                      validUnitReflection = true;
                    } else {
                      validUnitReflection = false;
                    }
                  }
                }
              "
            />
          </div>
        </div>
        <div v-else>
          <p>問題が発生しました。画面をリロードしてやり直してみてください。</p>
        </div>
        <div
          v-if="status === 'display'"
          class="buttons absolute flex flex-row top-0 right-0"
        >
          <MButton class="mr-2" @click="openRecordInput">{{
            translatedValue(generateObjectPath("registerRecord"))
          }}</MButton>
          <MButton @click="handleUnitReflectionInputs">{{
            unit && unit.data.selfReflection
              ? translatedValue(generateObjectPath("updateReflection"))
              : translatedValue(generateObjectPath("registerReflection"))
          }}</MButton>
        </div>
      </main>
    </template>
    <template #footer>
      <div v-if="status === 'display'">
        <!-- there is nothing to show -->
      </div>
      <div v-else-if="status === 'recordInput'" class="flex flex-row gap-2">
        <MButton
          negative
          @click="
            () => {
              clearRecordInput();
              status = 'display';
            }
          "
          >{{ translatedValue(generateObjectPath("cancel")) }}</MButton
        >
        <MButton :invalid="!validReflectionInput" @click="registerRecord">{{
          translatedValue(generateObjectPath("register"))
        }}</MButton>
      </div>
      <div v-else-if="status === 'recordEdit'" class="flex flex-row gap-2">
        <MButton
          negative
          @click="
            () => {
              clearRecordEditInput();
              status = 'display';
            }
          "
          >{{ translatedValue(generateObjectPath("cancel")) }}</MButton
        >
        <MButton :invalid="!eValidReflectionInput" @click="updateRecord">{{
          translatedValue(generateObjectPath("update"))
        }}</MButton>
        <MButton danger @click="deleteRecord">{{
          translatedValue(generateObjectPath("delete"))
        }}</MButton>
      </div>
      <div
        v-else-if="status === 'unitReflectionInput'"
        class="flex flex-row gap-2"
      >
        <MButton negative @click="clearUnitReflectionInput">{{
          translatedValue(generateObjectPath("cancel"))
        }}</MButton>
        <MButton
          :invalid="!validUnitReflection"
          @click="registerUnitReflection"
          >{{
            unit && unit.data.selfReflection
              ? translatedValue(generateObjectPath("update"))
              : translatedValue(generateObjectPath("register"))
          }}</MButton
        >
      </div>
      <div v-else>
        <!-- there's nothing to show here -->
      </div>
    </template>
  </MBaseModal>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import MIcon from "@/components/MIcon.vue";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/form/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import "firebase/firestore";
import "firebase/functions";
import {
  Unit,
  Reflection,
  reflectionCollectionKey,
  convertToReflection
} from "@/entities/reflection";
import MSLineChart from "@/components/analyze/MSLineChart.vue";
import dayjs from "dayjs";
import "dayjs/locale/ja";
import firebase from "firebase/app";
dayjs.locale("ja");

@Options({
  components: {
    MIcon,
    MBaseModal,
    MButton,
    MTextField,
    MSLineChart,
    MTextArea
  },
  emits: ["close", "aiReflectionUpdate"],
  props: {
    student: Object,
    unit: Object,
    canEdit: Boolean
  }
})
export default class MsEditModal extends Vue {
  student!: Student;
  unit: Unit | null = null;
  reflections: Reflection[] = [];
  datasets: {
    label: string;
    data: number[];
    borderColor: string;
    borderWidth: number;
    tension: number;
  }[] = [];
  labels: string[] = [];
  min = 0;
  max = 100;
  canEdit = false;
  unitName = "";
  graphTitle = "";
  secondRowTitle = "";
  thirdRowTitle = "";
  status:
    | "display"
    | "recordInput"
    | "unitReflectionInput"
    | "recordEdit"
    | "unitReflectionEdit" = "display";

  generateObjectPath(propName: string) {
    return "learningCard.modal" + "." + propName;
  }

  get translatedValue() {
    return store.state.translatedValue;
  }

  close() {
    this.$emit("close");
  }

  formatDate(uid: string): string {
    const unix = this.getUnixFromUid(uid);
    return dayjs.unix(unix).format("MM/DD(ddd)");
  }

  async getReflections() {
    if (!this.unit) return [];
    const snapshot = await this.unit.ref
      .collection(reflectionCollectionKey)
      .get();
    if (snapshot.empty) return [];
    return snapshot.docs
      .filter(doc => doc.exists && doc.data())
      .map(doc => convertToReflection(doc.data(), doc.ref));
  }

  isFutureDate(dateStr: string): boolean {
    const date = dayjs(dateStr);
    const today = dayjs();
    return date.diff(today, "day") >= 1;
  }

  // 記録登録系統
  recordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: ""
  };
  recordEntered = true;
  recordReflectionEntered = false;
  recordDescriptionEntered = false;
  validRecordInput = true;
  validRecordReflectionInput = false;
  validRecordDescriptionInput = false;
  get validReflectionInput() {
    return (
      this.validRecordInput &&
      this.validRecordReflectionInput &&
      this.validRecordDescriptionInput
    );
  }
  openRecordInput() {
    this.status = "recordInput";
  }
  clearRecordInput() {
    this.recordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: ""
    };
    this.recordEntered = true;
    this.recordReflectionEntered = false;
    this.recordDescriptionEntered = false;
    this.validRecordInput = true;
    this.validRecordReflectionInput = false;
    this.validRecordDescriptionInput = false;
  }
  async registerRecord() {
    if (!this.unit) return;
    if (!this.validReflectionInput) return;
    if (this.recordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.recordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    if (this.isFutureDate(this.recordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "登録中...");
      this.recordInput.recordDate = this.generateReflectionUid(
        this.reflections,
        this.recordInput.recordDate
      );
      const newRef = this.unit.ref
        .collection(reflectionCollectionKey)
        .doc(this.recordInput.recordDate);
      await newRef.set({
        uid: newRef.id,
        record: this.recordInput.record,
        reflection: this.recordInput.reflection,
        description: this.recordInput.description,
        createdAt: dayjs(this.recordInput.recordDate)
          .startOf("day")
          .unix(),
        updatedAt: dayjs().unix()
      });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の登録に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //記録編集系統
  reflectionToEdit: Reflection | null = null;
  eRecordInput: {
    recordDate: string;
    record: number;
    reflection: string;
    description: string;
    createdAt: number;
  } = {
    recordDate: dayjs().format("YYYY-MM-DD"),
    record: 0,
    reflection: "",
    description: "",
    createdAt: 0
  };
  eRecordEntered = true;
  eRecordReflectionEntered = true;
  eRecordDescriptionEntered = true;
  eValidRecordInput = true;
  eValidRecordReflectionInput = true;
  eValidRecordDescriptionInput = true;
  get eValidReflectionInput() {
    return (
      this.eValidRecordInput &&
      this.eValidRecordReflectionInput &&
      this.eValidRecordDescriptionInput
    );
  }
  openRecordEditInput(reflectionId: string) {
    if (!reflectionId) return;
    if (!this.reflections.length) return;
    const targetReflection = this.reflections.find(
      r => r.data.uid === reflectionId
    );
    if (!targetReflection) {
      alert(`${reflectionId}の記録が見つかりません。`);
      return;
    }
    this.reflectionToEdit = { ...targetReflection };
    const { data } = this.reflectionToEdit;
    this.eRecordInput = {
      recordDate: this.normalizeUid(data.uid),
      record: data.record,
      reflection: data.reflection,
      description: data.description,
      createdAt: data.createdAt
    };
    this.status = "recordEdit";
  }
  clearRecordEditInput() {
    this.reflectionToEdit = null;
    this.eRecordInput = {
      recordDate: dayjs().format("YYYY-MM-DD"),
      record: 0,
      reflection: "",
      description: "",
      createdAt: 0
    };
    this.eRecordEntered = true;
    this.eRecordReflectionEntered = true;
    this.eRecordDescriptionEntered = true;
    this.eValidRecordInput = true;
    this.eValidRecordReflectionInput = true;
    this.eValidRecordDescriptionInput = true;
  }
  async updateRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!this.eValidReflectionInput) return;

    if (this.eRecordInput.reflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    if (this.eRecordInput.description.length === 0) {
      alert("自由入力が入力されていません。");
      return;
    }
    if (this.isFutureDate(this.eRecordInput.recordDate)) {
      alert("未来の日付は登録できません。");
      return;
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "更新中...");

      const _date = this.normalizeUid(this.reflectionToEdit.data.uid);
      if (_date !== this.eRecordInput.recordDate) {
        this.eRecordInput.recordDate = this.generateReflectionUid(
          this.reflections,
          this.eRecordInput.recordDate
        );
        // 日付に変更がある場合は、新しく作って、今のを削除する
        const newRef = this.unit.ref
          .collection(reflectionCollectionKey)
          .doc(this.eRecordInput.recordDate);
        await Promise.all([
          newRef.set({
            uid: newRef.id,
            record: this.eRecordInput.record,
            reflection: this.eRecordInput.reflection,
            description: this.eRecordInput.description,
            createdAt: this.eRecordInput.createdAt,
            updatedAt: dayjs().unix()
          }),
          this.reflectionToEdit.ref.delete()
        ]);
      } else {
        await this.reflectionToEdit.ref.update({
          record: this.eRecordInput.record,
          reflection: this.eRecordInput.reflection,
          description: this.eRecordInput.description,
          updatedAt: dayjs().unix()
        });
      }

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert("記録の更新に失敗しました。");
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  async deleteRecord() {
    if (!this.unit) return;
    if (!this.reflectionToEdit) return;
    if (!window.confirm("この振り返りを削除しても良いですか？")) return;
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "削除中...");
      await this.reflectionToEdit.ref.delete();
    } catch (e) {
      alert("削除に失敗しました。");
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    }
  }

  // 振り返り登録系統
  handleUnitReflectionInputs() {
    if (!this.unit) return;
    // 将来的に処理の内容を切り替える必要があるかもしれないので分岐を分けておく
    if (this.unit.data.selfReflection) {
      this.openUnitReflectionInput();
    } else {
      this.openUnitReflectionInput();
    }
  }
  unitReflection = "";
  validUnitReflection = false;
  openUnitReflectionInput() {
    this.unitReflection =
      this.unit && this.unit.data.selfReflection
        ? this.unit.data.selfReflection
        : "";
    this.validUnitReflection = this.unitReflection.length > 0 ? true : false;
    this.status = "unitReflectionInput";
  }
  clearUnitReflectionInput() {
    this.unitReflection = "";
    this.validUnitReflection = false;
    this.status = "display";
  }
  async registerUnitReflection() {
    if (!this.unit) return;
    if (!this.validUnitReflection) return;
    if (!this.unit.data.selfReflection && this.unitReflection.length === 0) {
      alert("振り返りが入力されていません。");
      return;
    }
    const text = this.unit.data.selfReflection ? "更新" : "登録";
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", `振り返り${text}中...`);
      await this.unit.ref.update({ selfReflection: this.unitReflection });
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$router.go(0);
    } catch (e) {
      alert(`振り返りの${text}に失敗しました。`);
      console.error(e);
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  //AI振り返り系統
  async getGptMessage(): Promise<string> {
    if (!this.unit) throw new Error("単元がありません。");
    if (this.reflections.length === 0)
      throw new Error("振り返りデータがありません。");

    const prompt = `
# 役割
あなたは授業をサポートするチューターです。生徒の学習記録に対してコメントし、生徒の振り返りをサポートする役割です。
# 指示
以下の手順で、生徒の授業での学習記録を元にポジティブなコメントを書いてください。
1. 生徒の最新の取り組みと数値データ（例：50m走の結果や提出物の数）を確認します。
2. 観点別評価の項目ごとに、生徒がどのように成長しているかを具体的に示します。
3. 観点別評価の項目ごとに、生徒の努力や改善が見られるポイントを強調して称賛します。
4. 観点別評価の項目ごとに、前回の記録と比較して進歩が見られる場合、その進歩を具体的に示し、さらに前向きなコメントを付け加えます。
5. 生徒がこれからも努力を続けられるように励ましのメッセージを付け加えます。
# 観点別評価
1. 知識・理解
   - 運動の合理的な実践に関する具体的な知識や、生涯にわたって運動に親しむための科学的知識、及び運動の特性に応じた基本的な技能を身に付けている。
   - 個人生活における健康・安全について、課題解決に役立つ知識や技能を身に付けている。
2. 思考・判断・表現
   - 自己の課題に応じた運動の取り組み方や、健康の保持及び体力を高めるための運動の組み合わせ方を工夫し、それらを表現している。
   - 個人生活における健康課題を把握し、その解決を目指して科学的に考え、判断し、それらを表現している。
3. 主体的に学習に取り組む態度
   - 運動の楽しさや喜びを味わうことができるよう、運動の合理的な実践に主体的に取り組もうとしている。
   - 自他の健康を大切にし、健康の保持増進や回復に関する学習活動に主体的に取り組もうとしている。
# 制約条件
- コメントはこの文章で完結する内容とし、生徒からの返答を求めないようにしてください。
- コメントは前向きで励みになる内容にしてください。
- コメントは200~300文字とする。
`;
    let result = "";
    result = "#生徒の学習記録" + "\n" + `単元名: ${this.unit.data.name}` + "\n";
    result =
      result +
      `${this.graphTitle}: ` +
      "\n" +
      `${this.reflections
        .map(
          reflection =>
            dayjs
              .unix(this.getUnixFromUid(reflection.data.uid))
              .format("MM月DD日") +
            " : " +
            String(reflection.data.record)
        )
        .join("\n")}` +
      "\n";
    result =
      result +
      `${this.secondRowTitle}` +
      "\n" +
      `${this.reflections
        .map(
          reflection =>
            dayjs
              .unix(this.getUnixFromUid(reflection.data.uid))
              .format("MM月DD日") +
            " : " +
            reflection.data.reflection
        )
        .join("\n")}` +
      "\n";
    result =
      result +
      `${this.thirdRowTitle}` +
      "\n" +
      `${this.reflections
        .map(
          reflection =>
            dayjs
              .unix(this.getUnixFromUid(reflection.data.uid))
              .format("MM月DD日") +
            " : " +
            reflection.data.description
        )
        .join("\n")}` +
      "\n";
    if (store.state.lang === "en") {
      result =
        result +
        "\n\n" +
        `
      # 必須条件
      返答は必ず英語で返してください。
      `;
    }
    result = result.replace(/^\s+/gm, "");

    //TODO: デプロイ前に消すこと
    console.log("以下、投げられるプロンプト");
    console.log(prompt + "\n" + result);

    const call = firebase
      .app()
      .functions("asia-northeast1")
      .httpsCallable("get_message_generated_by_chatgpt");
    const res = await call({ prompt, messageText: result });
    return res.data.message as string;
  }

  async updateAiReflection(option: { actionType: "generate" | "delete" }) {
    if (!this.unit) return;
    if (option.actionType !== "generate" && option.actionType !== "delete")
      return;
    const isGenerating = option.actionType === "generate";
    const text = isGenerating ? "作成" : "削除";
    try {
      store.commit("SET_LOADING", true);
      store.commit(
        "SET_LOAD_TEXT",
        `AI振り返り${text}中...(このままお待ちください)`
      );
      const content = isGenerating ? await this.getGptMessage() : "";
      await this.unit.ref.update({ aiReflection: content });
      this.$emit("aiReflectionUpdate", { unitId: this.unit.data.uid, content });
    } catch (e) {
      alert(
        `AI振り返りの${text}に失敗しました。` +
          "\n" +
          (e.message ?? "不明なエラー")
      );
      console.error(e);
    } finally {
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    }
  }

  generateAiReflection() {
    if (!this.unit) return;
    if (!this.unit.data.selfReflection) {
      alert(
        "AIの振り返りを利用するには、単元の自身の振り返りを記入してください。"
      );
      return;
    }
    if (this.unit.data.aiReflection) {
      const res = window.confirm(
        "現在登録されている振り返りが上書きされますが、よろしいですか？"
      );
      if (!res) return;
    }

    this.updateAiReflection({ actionType: "generate" });
  }

  deleteAiReflection() {
    const res = window.confirm("AI振り返りを削除してもよろしいですか？");
    if (!res) return;
    this.updateAiReflection({ actionType: "delete" });
  }

  // ジェネラル系統
  generateReflectionUid(reflections: Reflection[], date: string): string {
    /*
    dateは、"2024-12-01"のような形で渡ってくる
    各uidは、"2024-12-01", "2024-12-01-1", "2024-12-01-2"のように定義するものとする
    */
    if (!reflections.length) return date;
    const uidSet = new Set(reflections.map(r => r.data.uid));
    if (!uidSet.has(date)) return date;

    let i = 1;
    while (uidSet.has(`${date}-${i}`)) i++;
    return `${date}-${i}`;
  }

  normalizeUid(date: string): string {
    // "2024-12-22-1"のようなデータが渡ってくる前提
    const parts = date.split("-").map(p => String(p));
    if (parts.length === 3) {
      return date;
    } else if (parts.length === 4) {
      return `${parts[0]}-${parts[1]}-${parts[2]}`;
    } else {
      throw new Error(`Invalid date format -- ${date}`);
    }
  }

  getUnixFromUid(date: string): number {
    // "2024-12-22-1"のようなデータが渡ってくる前提
    if (!date) return 0;
    const _date = (() => {
      try {
        return this.normalizeUid(date);
      } catch (_) {
        return "";
      }
    })();
    if (!_date) return 0;

    return dayjs(_date)
      .startOf("day")
      .unix();
  }

  sortReflectionsByUid(reflections: Reflection[], option = { asc: true }) {
    if (!reflections || !Array.isArray(reflections) || !reflections.length) {
      return [];
    }
    const { asc } = option;
    const _reflections = [...reflections];
    return _reflections.sort((a, b) => {
      const diff =
        this.getUnixFromUid(a.data.uid) - this.getUnixFromUid(b.data.uid);
      if (diff !== 0) return asc ? diff : diff * -1;
      return a.data.updatedAt - b.data.updatedAt;
    });
  }

  async created(): Promise<void> {
    if (!this.student) {
      alert("生徒情報が取得できません。");
      this.$emit("close");
    }
    if (!this.unit) {
      alert("単元情報が取得できません。");
      this.$emit("close");
    }
    try {
      store.commit("SET_LOADING", true);
      store.commit("SET_LOAD_TEXT", "情報取得中...");
      const _unit = { ...this.unit } as Unit;
      this.unitName = _unit.data.name;
      this.graphTitle = _unit.data.graphTitle || "数値(記録)";
      this.secondRowTitle = _unit.data.secondRowTitle || "授業ごとの振り返り";
      this.thirdRowTitle = _unit.data.thirdRowTitle || "自由入力";

      const _reflections = await this.getReflections();
      this.reflections = this.sortReflectionsByUid(_reflections);

      if (this.reflections.length > 0) {
        const records = this.reflections.map(_ => +_.data.record);
        if (this.reflections.length > 1) {
          this.min = Math.min(...records) - 1;
          this.max = Math.max(...records) + 1;
        } else {
          const record = +this.reflections[0].data.record;
          this.min = record < 0 ? record - 1 : 0;
          this.max = record + 5;
        }
      }

      this.labels =
        this.reflections.length > 0
          ? this.reflections.map(_ =>
              dayjs.unix(this.getUnixFromUid(_.data.uid)).format("MM-DD")
            )
          : [];
      this.datasets =
        this.reflections.length > 0
          ? [
              {
                label: this.unitName,
                data: this.reflections.map(_ => _.data.record),
                borderColor: "rgb(75, 192, 192)",
                borderWidth: 2,
                tension: 0.1
              }
            ]
          : [];

      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
    } catch (e) {
      console.error(e);
      alert("情報の取得に失敗しました。");
      store.commit("SET_LOADING", false);
      store.commit("SET_LOAD_TEXT", "");
      this.$emit("close");
    }
  }
}
</script>

<style lang="scss" scoped>
.reflection-wrapper {
  width: 100%;
  max-width: 780px;
  margin: 0 auto;
}
.line-chart-canvas {
  width: 80%;
  margin: 0 auto;
}
.pt-none {
  padding-top: 0 !important;
}
</style>
